import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import Search from '@/components/layout/Search/search.vue'
import QRCode from '@/components/layout/QRCode/QRCode.vue'
import fab from 'vue-fab'
import Roles from '@/shared/mixins/hasRoles'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'

export default {
  name: 'Professores',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination,
    [Search.name]: Search,
    QRCode,
    fab
  },

  mixins: [Roles],

  data: () => ({
    teachers: [],
    labelSearch: 'Professores',
    showModalTeacher: false,
    showModalQRCode: false,
    toQrCode: {},
    fields: [
      {
        key: 'code',
        label: 'CÓDIGO',
        class: 'left-header-border text-center'
      },
      {
        key: 'name',
        label: 'NOME',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'documentCPF',
        label: 'CPF',
        class: 'middle-header text-center'
      },
      {
        key: 'birthDate',
        label: 'DATA DE NASCIMENTO',
        class: 'middle-header text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 10,
      index: 1
    }
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Professores',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getAll()
  },

  methods: {
    getAll() {
      loading.push()
      TeacherRepository.GetAll(this.searchText, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.teachers = res.data.data
          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar professores', 'ERRO')
        })
    },

    search(searchText) {
      this.searchText = searchText
      this.getAll()
    },

    onDelete() {
      loading.push()
      TeacherRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getAll()
          this.showModalTeacher = false
          toast.success('Professor excluído com sucesso!', 'EXCLUSÃO DE PROFESSORES')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir professores', 'ERRO')
        })
    },

    selectRow(id) {
      this.showModalTeacher = true
      this.id = id
    },

    onQRCode(obj) {
      if (!obj) return

      if (obj.status != 1) {
        toast.info('Professor inativo, não será possível gerar QRCode', 'GERAR QRCODE')
        return
      }

      this.showModalQRCode = true
      this.toQrCode = obj
    },

    onCloseModalQrCode() {
      this.showModalQRCode = false
    }
  }
}
